/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <SheetJSApp />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/
import React, { useState, useEffect } from "react";
// import { useEffect } from "react/cjs/react.development";
import "./App.css";
import XLSX from "xlsx";
import ClienteStatus from "./ClienteStatus";
import api, { loginCheck } from "./services/api";

export default class SheetJSApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
    };
    this.handleFile = this.handleFile.bind(this);
    // this.exportFile = this.exportFile.bind(this);
  }
  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      const makeCols = make_cols(ws["!ref"]);
      // console.log(makeCols,ws)
      this.setState({ ...this.state, data: data, cols: makeCols });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }
  // exportFile() {
  //   /* convert state to workbook */
  //   const ws = XLSX.utils.aoa_to_sheet(this.state.data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  //   /* generate XLSX file and send to client */
  //   XLSX.writeFile(wb, "sheetjs.xlsx");
  // }
  render() {
    return (
      <DragDropFile handleFile={this.handleFile}>
        <br />
        <div className="row">
          <div className="col-xs-8">
            <DataInput handleFile={this.handleFile} />
          </div>
          <div className="col-xs-4">
            <UserInput />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-xs-12">
            <button
              disabled={!this.state.data.length}
              className="btn btn-success"
              onClick={this.exportFile}
            >
              Export
            </button>
          </div>
        </div> */}

        <br />
        <div className="row">
          <div className="col-xs-12">
            <OutTable data={this.state.data} cols={this.state.cols} />
          </div>
        </div>
      </DragDropFile>
    );
  }
}

const UserInput = () => {
  // constructor(props) {
  //   super(props);
  //   this.handleChange = this.handleChange.bind(this);
  // }
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginOk, setLoginOK] = useState("");

  const handleApiAuth = async () => {
    // const username = document.getElementById("login").value;
    // const password = document.getElementById("password").value;
    // if (username.length > 3 && password.length > 3) {
    // if (await loginCheck(username, password)) {
    //   setLoginOK('Ok')
    api.defaults.auth = {
      username,
      password,
    };
    // }else{
    //   setLoginOK('Fail')
    // }
    // }
  };

  useEffect(() => {
    // setLoginOK('...')

    const timeOutId = setTimeout(() => handleApiAuth(username, password), 800);
    return () => clearTimeout(timeOutId);
  }, [username, password]);

  return (
    <form className="form-inline">
      <div className="form-group">
        <label htmlFor="file">Login</label>
        <input
          type="text"
          className="form-control"
          id="login"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          className="form-control"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* {loginOk} */}
      </div>
    </form>
  );
};
/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group">
          <label htmlFor="file">Relatório SPC - XLS</label>
          <input
            type="file"
            className="form-control"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />{" "}
        </div>

        {/* <div className="form-group">
          <label htmlFor="doc_column">Coluna CPF/CNPJ</label>
          <input
            type="number"
            className="form-control"
            id="doc_column"
            placeholder="Coluna do documento"
            maxLength={2}
            value={this.state?.config?.doc_column}
            onChange={(e) =>
              this.setState({
                ...this.state,
                config: { doc_field: e.target.value },
              })
            }
          />
          {console.log(this.state)}
        </div> */}
      </form>
    );
  }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          {/* <thead>
            <tr>
              <th>Total atrasado</th>
              <th>Cliente</th>
              {this.props.cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead> */}
          <tbody>
            {this.props.data.map((r, i) => (
              // i > 0 &&
              <tr key={i}>
                {/* // Colunas Extras */}
                {i < 1 ? (
                  <>
                    <td>Total atrasado</td>
                    <td>Cliente</td>
                  </>
                ) : (
                  <ClienteStatus doc={r[1]} valor_debito={r[5] / 100} />
                )}

                {this.props.cols.map((c) => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}




/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
