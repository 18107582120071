import qs from 'qs';
import React, {useState, useEffect} from 'react';

import useSWR from 'swr';
import api from './services/api';


const ClienteStatus = ({ doc = undefined, valor_debito }) => {
  const [cliente, setCliente] = useState(null);
  const [error, setError] = useState(0);

  useEffect(() => {
    // const docRegex = async (doc) => await doc + ' '.replace(/[^0-9]+/g, "")
    // console.log('doc',doc, docX )

    // if (!doc || doc === "") return null;

    doc = `${doc}`.replace(/[^0-9]+/g, "");
    const campo = doc.length >= 13 ? "cnpj" : "cpf";

    var options = {
      method: "POST",
      url: "/clientes",
      params: { json: "" },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest",
      },
      data: qs.stringify({
        [campo]: doc,
        revendas_id: "-1",
        vendedor_id: "-1",
        sidx: "servico_id",
        sord: "desc",
        rows: "1",
        page: "1",
      }),
    };

    api
      .request(options)
      .then(function (response) {
        const clientesSelecionado = response.data?.rows;
        const clienteSelecionado = clientesSelecionado
          ? clientesSelecionado[0]?.cell
          : undefined;

        // console.log("selecionado Cliente:",clienteSelecionado)
        setCliente(clienteSelecionado);
      })
      .catch(function (error) {
        console.error(error);
        setError(1);
      });
  }, []);

  return (
    <>
      {cliente && !error ? (
        <>
          <td>
            <ClienteFinanceiro
              cliente_id={cliente[0] > 0 ? cliente[0] : null}
              valor_debito={valor_debito}
            />
          </td>
          <td>
            <span>
              <a
                href={`https://admin.x-pcsolucoes.com.br/clientes/consultar/${cliente[0]}#ui-tabs-2`}
                target="_blank"
              >{`${cliente[3]}`}</a>
              {` - ${cliente[6]}`}
            </span>
          </td>
        </>
      ) : error ? (
        <>
          <td>-</td>
          <td>
            <span style={{ color: "red" }} title={error}>
              Erro...
            </span>
          </td>
        </>
      ) : cliente === undefined ? (
        <>
          <td> </td>
          <td>
            <span>Não Encontrado</span>
          </td>
        </>
      ) : (
        <>
          <td> </td>
          <td>
            <span>Carregando...</span>
          </td>
        </>
      )}
    </>
  );
};

export default ClienteStatus;

const ClienteFinanceiro = ({ cliente_id = undefined, valor_debito }) => {
  const [financeiro, setFinanceiro] = useState(null);
  const [error, setError] = useState(0);
  const [total_incluido] = useState(parseFloat(valor_debito));
  const [total_atrasado, setTotalAtrasado] = useState(0);
  const [total_diferenca, setTotalDiferenca] = useState(0);

  var formatoBR = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  // console.log('doc',doc, docX )

  useEffect(() => {
    if (!cliente_id) return null;

    var date = new Date("");

    var yesterday = date.setDate(date.getDate() - 1);
    var options = {
      method: "POST",
      url: "/clientes_cobrancas/pesquisa",
      params: { json: "" },
      data: qs.stringify({
        rows: "1000",
        page: "1",
        sidx: "cli_nome",
        sord: "asc",
        status: "0",
        vencimento_inicio: "01/01/1970",
        vencimento_fim: yesterday,
        clientes_id: cliente_id,
      }),
    };

    api
      .request(options)
      .then(function (response) {
        // console.log("Financeiro Cliente:",response.data)
        setFinanceiro(response.data.rows);

        const valor_atrasado = response.data.rows.reduce((acc, item) => {
          if (item["vencido"]) {
            return acc + parseFloat(item["valor_total"]);
          } else {
            return acc;
          }
        }, 0.0);

        setTotalAtrasado(valor_atrasado);

        setTotalDiferenca(
          valor_atrasado.toFixed(2) - total_incluido.toFixed(2)
        );
      })
      .catch(function (error) {
        console.error(error);
        setError(error);
      });
  }, []);

  // console.log({ total_diferenca, total_atrasado, total_incluido });

  return (
    <>
      {financeiro && !error ? (
        <>
          {total_atrasado === 0.0 ? (
            <span
              className="acao_indicada danger"
              style={{ color: "red", fontWeight: "bold" }}
            >
              REMOVER SPC
            </span>
          ) : total_diferenca === 0.0 ? (
            <span
              className="acao_indicada primary"
              style={{ color: "blue", fontWeight: "bold" }}
            >
              MANTER SPC
            </span>
          ) : (
            <span
              className="acao_indicada primary"
              style={{ color: "orange", fontWeight: "bold" }}
            >
              ATUALIZAR SPC
            </span>
          )}
          <br />
          <span
            className="total_atrasado"
            title="Total Atrasado no Resumo Financeiro (sem juros)"
          >
            {total_atrasado.toLocaleString("pt-BR", formatoBR)}
          </span>{" "}
          <span
            className="total_diferenca"
            title="Diferença (Resumo Financeiro - Cadastro SPC)"
          >
            ({total_diferenca.toLocaleString("pt-BR", formatoBR)})
          </span>
        </>
      ) : !error ? (
        <>
          <span>Carregando...</span>
        </>
      ) : (
        <>
          <span style={{ color: "red" }} title={error}>
            Erro...
          </span>
        </>
      )}
    </>
  );
};