import axios  from 'axios';

const options = {
  baseURL: process.env.REACT_APP_ISPBOX_ADMIN_URL,
  headers: {
    Accept: 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
};

const api = axios.create(options);

// api.defaults.auth = {
//   username: process.env.REACT_APP_ISPBOX_ADMIN_USERNAME,
//   password: process.env.REACT_APP_ISPBOX_ADMIN_PASSWORD,
// }

export default api;

export const loginCheck = async (username, password) => {
  try {
    const query = await axios.get(
      `${process.env.REACT_APP_ISPBOX_ADMIN_URL}/usuarios/alterarsenha`,
      {
        auth: {
          username,
          password,
        },
      }
    );
    // console.log(query)
    return query.request.path.includes("/usuarios/alterarsenha");
  } catch (error) {
    return console.error(error);
  }
}
